html,
body,
#root,
.App {
  font-size: 11px;
  min-height: 100vh;
  height: 100vh;
}

@media only screen and (max-width: 1024px) {
  html {
    font-size: 9.4px;
  }
}
@media only screen and (max-width: 768px) {
  html {
    font-size: 9.5px;
    width: fit-content;
    border: hidden;
  }
}
@media only screen and (max-width: 400px) {
  html {
    font-size: 9.411764705882353px;
  }
}

html,
body {
  margin: 0 auto;
}
body {
  background: #151515;
  font-family: "lora-regular", serif;
  font-size: 1.7rem;
  line-height: 3rem;
  color: #6e6e6e;
}

/**
* links - (_document-setup.scss)
* -------------------------------------------------------------------
*/
a,
a:visited {
  color: #000000;
  transition: all 0.3s ease-in-out;
}
a:hover,
a:focus,
a:active {
  color: #ff0077;
  outline: 0;
}

/** 
 * ===================================================================
 * 03. typography & general theme styles - (_document-setup.scss)
 *
 * ------------------------------------------------------------------- 
 */
h1,
h2,
h3,
h4,
h5,
h6,
.h01,
.h02,
.h03,
.h04,
.h05,
.h06 {
  font-family: "poppins-semibold", sans-serif;
  color: #313131;
  font-style: normal;
  text-rendering: optimizeLegibility;
  margin-bottom: 2.1rem;
}
h3,
.h03,
h4,
.h04 {
  margin-bottom: 1.8rem;
}
h5,
.h05,
h6,
.h06 {
  font-family: "poppins-bold";
  margin-bottom: 1.2rem;
}
h1,
.h01 {
  font-size: 3.1rem;
  line-height: 1.355;
  letter-spacing: -0.1rem;
}
@media only screen and (max-width: 600px) {
  h1,
  .h01 {
    font-size: 2.6rem;
    letter-spacing: -0.07rem;
  }
}
h2,
.h02 {
  font-size: 2.4rem;
  line-height: 1.25;
}
h3,
.h03 {
  font-size: 2rem;
  line-height: 1.5;
}
h4,
.h04 {
  font-size: 1.7rem;
  line-height: 1.765;
}
h5,
.h05 {
  font-size: 1.4rem;
  line-height: 1.714;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
}
h6,
.h06 {
  font-size: 1.3rem;
  line-height: 1.846;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
}
p img {
  margin: 0;
}
p.lead {
  font-family: "lora-regular", serif;
  font-size: 2rem;
  line-height: 1.8;
  color: #888888;
}
@media only screen and (max-width: 768px) {
  p.lead {
    font-size: 1.7rem;
  }
}
em,
i,
strong,
b {
  font-size: 1.7rem;
  line-height: 3rem;
  font-style: normal;
  font-weight: normal;
}
em,
i {
  font-family: "lora-italic", serif;
}
strong,
b {
  font-family: "lora-bold", serif;
}
small {
  font-size: 1.2rem;
  line-height: inherit;
}
blockquote {
  margin: 3rem 0;
  padding-left: 4rem;
  position: relative;
}
blockquote:before {
  content: "\201C";
  font-size: 8rem;
  line-height: 0px;
  margin: 0;
  color: #313131;
  font-family: arial, sans-serif;
  position: absolute;
  top: 3rem;
  left: 0;
}
blockquote p {
  font-family: georgia, serif;
  font-style: italic;
  padding: 0;
  font-size: 1.9rem;
  line-height: 1.737;
}
blockquote cite {
  display: block;
  font-size: 1.3rem;
  font-style: normal;
  line-height: 1.616;
}
blockquote cite:before {
  content: "\2014 \0020";
}
blockquote cite a,
blockquote cite a:visited {
  color: #888888;
  border: none;
}
abbr {
  font-family: "poppins-bold", serif;
  font-variant: small-caps;
  text-transform: lowercase;
  letter-spacing: 0.05rem;
  color: #888888;
}
var,
kbd,
samp,
code,
pre {
  font-family: Consolas, "Andale Mono", Courier, "Courier New", monospace;
}
pre {
  padding: 2.4rem 3rem 3rem;
  background: #f1f1f1;
}
code {
  font-size: 1.4rem;
  margin: 0 0.2rem;
  padding: 0.3rem 0.6rem;
  white-space: nowrap;
  background: #f1f1f1;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
}
pre > code {
  display: block;
  white-space: pre;
  line-height: 2;
  padding: 0;
  margin: 0;
}
pre.prettyprint > code {
  border: none;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
mark {
  background: #fff49b;
  color: #000;
}
hr {
  border: solid #d2d2d2;
  border-width: 1px 0 0;
  clear: both;
  margin: 2.4rem 0 1.5rem;
  height: 0;
}

/**
 * Lists - (_document-setup.scss)  
 * -------------------------------------------------------------------
 */
ol {
  list-style: decimal;
}
ul {
  list-style: disc;
}
li {
  display: list-item;
}
ol,
ul {
  margin-left: 1.7rem;
}
ul li {
  padding-left: 0.4rem;
}
ul ul,
ul ol,
ol ol,
ol ul {
  margin: 0.6rem 0 0.6rem 1.7rem;
}
ul.disc li {
  display: list-item;
  list-style: none;
  padding: 0 0 0 0.8rem;
  position: relative;
}
ul.disc li::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ff0077;
  position: absolute;
  left: -17px;
  top: 11px;
  vertical-align: middle;
}
dt {
  margin: 0;
  color: #ff0077;
}
dd {
  margin: 0 0 0 2rem;
}

/**
 * tables - (_document-setup.scss)  
 * -------------------------------------------------------------------
 */
table {
  border-width: 0;
  width: 100%;
  max-width: 100%;
  font-family: "lora-regular", sans-serif;
}
th,
td {
  padding: 1.5rem 3rem;
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
}
th {
  color: #313131;
  font-family: "poppins-bold", sans-serif;
}
td {
  line-height: 1.5;
}
th:first-child,
td:first-child {
  padding-left: 0;
}
th:last-child,
td:last-child {
  padding-right: 0;
}
.table-responsive {
  overflow-x: auto;
}

/**
 * Spacing - (_document-setup.scss)  
 * -------------------------------------------------------------------
 */
button,
.button {
  margin-bottom: 1.2;
}
fieldset {
  margin-bottom: 1.5rem;
}
input,
textarea,
select,
pre,
blockquote,
figure,
table,
p,
ul,
ol,
dl,
form,
.fluid-video-wrapper,
.ss-custom-select {
  margin-bottom: 3rem;
}

/**
 * floated image - (_document-setup.scss)  
 * -------------------------------------------------------------------
 */
img.pull-right {
  margin: 0.9rem 0 0 2.4rem;
}
img.pull-left {
  margin: 0.9rem 2.4rem 0 0;
}

/**
 * block grid paddings - (_document-setup.scss) 
 * -------------------------------------------------------------------
 */
.bgrid {
  padding: 0 20px;
}
@media only screen and (max-width: 1024px) {
  .bgrid {
    padding: 0 18px;
  }
}
@media only screen and (max-width: 768px) {
  .bgrid {
    padding: 0 15px;
  }
}
@media only screen and (max-width: 600px) {
  .bgrid {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 400px) {
  .bgrid {
    padding: 0;
  }
}

/**
 * pace.js styles - minimal  - (_document-setup.scss)
 * -------------------------------------------------------------------
 */
.pace {
  pointer-events: none;

  user-select: none;
}
.pace-inactive {
  display: none;
}
.pace .pace-progress {
  background: #ff0077;
  position: fixed;
  z-index: 900;
  top: 0;
  right: 100%;
  width: 100%;
  height: 6px;
}

@-webkit-keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/** 
 * ===================================================================
 * 05. forms - (_forms.scss)
 *
 * ------------------------------------------------------------------- 
 */
fieldset {
  border: none;
}
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
  display: block;
  height: 6rem;
  padding: 1.5rem 0;
  border: 0;
  outline: none;
  color: #313131;
  font-family: "poppins-regular", sans-serif;
  font-size: 1.5rem;
  line-height: 3rem;
  max-width: 100%;
  background: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);

  transition: all 0.3s ease-in-out;
}
.ss-custom-select {
  position: relative;
  padding: 0;
}
.ss-custom-select select {
  appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
  margin: 0;
  line-height: 3rem;
  vertical-align: middle;
}
.ss-custom-select select option {
  padding-left: 2rem;
  padding-right: 2rem;
}
.ss-custom-select select::-ms-expand {
  display: none;
}
.ss-custom-select::after {
  content: "\f0d7";
  font-family: "FontAwesome";
  position: absolute;
  top: 50%;
  right: 1.5rem;
  margin-top: -10px;
  bottom: auto;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 18px;
  text-align: center;
  pointer-events: none;
  color: #252525;
}

/* IE9 and below */
.oldie .ss-custom-select::after {
  display: none;
}
textarea {
  min-height: 25rem;
}
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  color: #cc005f;
  border-bottom: 1px solid #ff0077;
}
label,
legend {
  font-family: "poppins-bold", sans-serif;
  font-size: 1.4rem;
  margin-bottom: 0.6rem;
  color: #3b3b3b;
  display: block;
}
input[type="checkbox"],
input[type="radio"] {
  display: inline;
}
label > .label-text {
  display: inline-block;
  margin-left: 1rem;
  font-family: "poppins-regular", sans-serif;
  line-height: inherit;
}
label > input[type="checkbox"],
label > input[type="radio"] {
  margin: 0;
  position: relative;
  top: 0.15rem;
}

/**
 * Style Placeholder Text  
 * -
 */
::-webkit-input-placeholder {
  color: #a1a1a1;
}
:-moz-placeholder {
  color: #a1a1a1; /* Firefox 18- */
}
::-moz-placeholder {
  color: #a1a1a1; /* Firefox 19+ */
}
:-ms-input-placeholder {
  color: #a1a1a1;
}
.placeholder {
  color: #a1a1a1 !important;
}

/** 
 * ===================================================================
 * 06. buttons - (_button-essentials.scss)
 *
 * ------------------------------------------------------------------- 
 */
.button,
a.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  display: inline-block;
  font-family: "poppins-bold", sans-serif;
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  height: 5.4rem;
  line-height: 5.4rem;
  padding: 0 3rem;
  margin: 0 0.3rem 1.2rem 0;
  background: #d8d8d8;
  color: #313131;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  border: none;

  transition: all 0.3s ease-in-out;
}
.button:hover,
a.button:hover,
button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
.button:focus,
button:focus,
input[type="submit"]:focus,
input[type="reset"]:focus,
input[type="button"]:focus {
  background: #bebebe;
  color: #000000;
  outline: 0;
}
.button.button-primary,
a.button.button-primary,
button.button-primary,
input[type="submit"].button-primary,
input[type="reset"].button-primary,
input[type="button"].button-primary {
  background: #313131;
  color: #ffffff;
}
.button.button-primary:hover,
a.button.button-primary:hover,
button.button-primary:hover,
input[type="submit"].button-primary:hover,
input[type="reset"].button-primary:hover,
input[type="button"].button-primary:hover,
.button.button-primary:focus,
button.button-primary:focus,
input[type="submit"].button-primary:focus,
input[type="reset"].button-primary:focus,
input[type="button"].button-primary:focus {
  background: #1f1f1f;
}
button.full-width,
.button.full-width {
  width: 100%;
  margin-right: 0;
}
button.medium,
.button.medium {
  height: 5.7rem !important;
  line-height: 5.7rem !important;
  padding: 0 1.8rem !important;
}
button.large,
.button.large {
  height: 6rem !important;
  line-height: 6rem !important;
  padding: 0rem 3rem !important;
}
button.stroke,
.button.stroke {
  background: transparent !important;
  border: 3px solid #313131;
  line-height: 4.8rem;
}
button.stroke.medium,
.button.stroke.medium {
  line-height: 5.1rem !important;
}
button.stroke.large,
.button.stroke.large {
  line-height: 5.4rem !important;
}
button.stroke:hover,
.button.stroke:hover {
  border: 3px solid #ff0077;
  color: #ff0077;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * skillbars - (_skillbars.scss)
 * -------------------------------------------------------------------
 */
.skill-bars {
  list-style: none;
  margin: 6rem 0 3rem;
}
.skill-bars li {
  height: 0.6rem;
  background: #a1a1a1;
  width: 100%;
  margin-bottom: 6rem;
  padding: 0;
  position: relative;
}
.skill-bars li strong {
  position: absolute;
  left: 0;
  top: -3rem;
  font-family: "poppins-bold", sans-serif;
  color: #313131;
  letter-spacing: 0.2rem;
  font-size: 1.5rem;
  line-height: 2.4rem;
}
.skill-bars li .progress {
  background: #313131;
  position: relative;
  height: 100%;
}
.skill-bars li .progress span {
  position: absolute;
  right: 0;
  top: -3.6rem;
  display: block;
  font-family: "poppins-regular", sans-serif;
  color: white;
  font-size: 1.1rem;
  line-height: 1;
  background: #313131;
  padding: 0.6rem 0.6rem;
  border-radius: 3px;
}
.skill-bars li .progress span::after {
  position: absolute;
  left: 50%;
  bottom: -5px;
  margin-left: -5px;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-top: 5px solid #313131;
  content: "";
}

.skill-bars li .percent70 {
  width: 70%;
}
.skill-bars li .percent75 {
  width: 75%;
}
.skill-bars li .percent80 {
  width: 80%;
}
.skill-bars li .percent85 {
  width: 85%;
}
.skill-bars li .percent90 {
  width: 90%;
}
.skill-bars li .percent95 {
  width: 95%;
}
.skill-bars li .percent100 {
  width: 100%;
}

/** 
 * ===================================================================
 * 08. common styles (_layout.scss)
 *
 * ------------------------------------------------------------------- 
 */
.grey-section {
  background: #ebebeb;
}
.grey-section p.lead {
  color: #7d7d7d;
}
.section-intro {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 3.6rem;
  position: relative;
}
.section-intro h1 {
  font-family: "poppins-semibold", serif;
  font-size: 3.6rem;
  color: #313131;
  line-height: 1.25;
  margin-bottom: 1.2rem;
}
.section-intro h5 {
  color: #ff0077;
  font-size: 1.6rem;
  line-height: 1.875;
  margin-bottom: 0.3rem;
  letter-spacing: 0.4rem;
}

/**
 * responsive:
 * common styles
 * ------------------------------------------------------------------- 
 */
@media only screen and (max-width: 768px) {
  .section-intro {
    max-width: 650px;
  }
  .section-intro h1 {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 600px) {
  .section-intro h1 {
    font-size: 2.6rem;
  }
  .section-intro h5 {
    font-size: 1.5rem;
    letter-spacing: 0.3rem;
  }
}
@media only screen and (max-width: 400px) {
  .section-intro h1 {
    font-size: 2.4rem;
  }
}

/** 
 * ===================================================================
 * 09. header styles - (_layout.scss)
 *
 * ------------------------------------------------------------------- 
 */
header {
  position: fixed;
  width: 100%;
  min-height: 66px;
  z-index: 600;
}
header .row {
  position: relative;
  min-height: 66px;
}
header .top-bar {
  display: block;
  background: #000000;
  min-width: 220px;
  min-height: 66px;
  position: absolute;
  left: 90px;
  top: 0;
}

/**
 * responsive:
 * header styles
 * --------------------------------------------------------------- 
 */
@media only screen and (max-width: 1024px) {
  header .top-bar {
    left: 60px;
  }
}
@media only screen and (max-width: 768px) {
  header .top-bar {
    left: 50px;
  }
}
@media only screen and (max-width: 600px) {
  header .top-bar {
    left: 35px;
  }
}
@media only screen and (max-width: 400px) {
  header .top-bar {
    left: 25px;
  }
}

/** 
 * ===================================================================
 * 10. intro - (_layout.scss) 
 *
 * ------------------------------------------------------------------- 
 */
#intro {
  background: #151515 url(../images/intro-bg.jpg) no-repeat center bottom;
  background-size: cover;
  background-attachment: local;
  width: 100%;
  height: 100vh;
  min-height: 720px;
  display: table;
  position: relative;
  text-align: center;
}

.intro-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #111111;
  opacity: 0.85;
}

.intro-content {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  transform: translateY(-2.1rem);
}
.intro-content h1 {
  text-align: center;
  justify-content: center;
  color: white;
  position: relative;
  font-family: "poppins-medium", sans-serif;
  font-size: 8.4rem;
  line-height: 1.071;
  max-width: 900px;
  margin-top: 0;
  margin-bottom: 0.6rem;
  margin-left: auto;
  margin-right: auto;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}
.intro-content h5 {
  text-align: center;
  color: #cc005f;
  font-family: "poppins-bold", sans-serif;
  font-size: 2.3rem;
  line-height: 1.565;
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}
.intro-content .intro-position {
  font-family: "lora-regular", serif;
  font-size: 1.7rem;
  line-height: 2.4rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  color: #ffffff;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}
.intro-content .intro-position span {
  display: inline-block;
}
.intro-content .intro-position span::after {
  content: "|";
  text-align: center;
  display: inline-block;
  padding: 0 8px 0 14px;
  color: rgba(255, 255, 255, 0.3);
}
.intro-content .intro-position span:first-child::before {
  content: "|";
  text-align: center;
  display: inline-block;
  padding: 0 14px 0 8px;
  color: rgba(255, 255, 255, 0.3);
}
.intro-content .button {
  color: #ffffff !important;
  border-color: rgba(255, 255, 255, 0.3);
  height: 6rem !important;
  line-height: 5.4rem !important;
  padding: 0 3.5rem 0 3rem !important;
  margin-top: 0.6rem;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
}
.intro-content .button:hover,
.intro-content .button:focus {
  border-color: #cc005f;
}
.intro-content .button::after {
  display: inline-block;

  font-size: 1.6rem;
  line-height: inherit;
  text-align: center;
  position: relative;
  left: 1.2rem;
}

.intro-social {
  display: block;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 7.2rem;
  font-size: 3.3rem;
  margin: 0;
  padding: 0;
}
.intro-social li {
  display: inline-block;
  margin: 0 20px;
  padding: 0;
}
.intro-social li a,
.intro-social li a:visited {
  color: #ffffff;
}
.intro-social li a:hover,
.intro-social li a:focus {
  color: #cc005f;
}

/**
 * responsive:
 * Intro
 * --------------------------------------------------------------- 
 */
@media only screen and (max-width: 1024px) {
  .intro-content h1 {
    font-size: 7.6rem;
  }
  .intro-social {
    font-size: 3rem;
  }
  .intro-social li {
    margin: 0 15px;
  }
}
@media only screen and (max-width: 768px) {
  #intro {
    min-height: 660px;
  }
  .intro-content h1 {
    font-size: 5.2rem;
  }
  .intro-content h5 {
    font-size: 1.8rem;
  }
  .intro-content .intro-position {
    font-size: 1.3rem;
  }
  .intro-social {
    font-size: 2.5rem;
  }
  .intro-social li {
    margin: 0 10px;
  }
}
@media only screen and (max-width: 600px) {
  #intro {
    min-height: 600px;
  }
  .intro-content h1 {
    font-size: 4.6rem;
    margin-bottom: 0.6rem;
  }
  .intro-content h5 {
    font-size: 1.5rem;
    margin-bottom: 0.3rem;
    letter-spacing: 0.2rem;
  }
  .intro-content .intro-position {
    font-size: 1.2rem;
  }
  .intro-content .intro-position span {
    padding: 0 0.6rem;
  }
  .intro-content .intro-position span::before,
  .intro-content .intro-position span::after {
    display: none !important;
  }
  .intro-social {
    font-size: 2.4rem;
  }
}

/** 
 * ===================================================================
 * 11. about - (_layout.scss)
 *
 * ------------------------------------------------------------------- 
 */
#about {
  padding-top: 12rem;
  padding-bottom: 15rem;
}
#about .section-intro {
  margin-bottom: 3rem;
}

.intro-info {
  margin-top: 4.2rem;
  margin-right: -30px;
  margin-left: -30px;
}
.intro-info img {
  height: 9rem;
  width: 9rem;
  border-radius: 50%;
  margin: 0.9rem 0 0 0;
  float: left;
}
.intro-info .lead {
  text-align: left;
  padding-left: 13rem;
}

.about-content {
  position: relative;
  text-align: left;
  max-width: 850px;
  margin-bottom: 3.6rem;
}
.about-content h3 {
  font-family: "poppins-bold", sans-serif;
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
}
.about-content .info-list {
  list-style: none;
  margin-left: 0;
}
.about-content .info-list li {
  padding: 0 0 1.5rem 0;
  margin-bottom: 0.6rem;
}
.about-content .info-list li strong {
  font-family: "poppins-bold", sans-serif;
  color: #313131;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 1.5rem;
  line-height: 3rem;
}
.about-content .info-list li span {
  display: block;
  font-family: "poppins-regular", sans-serif;
  color: #888888;
  font-size: 1.5rem;
  line-height: 1;
}
.about-content .skill-bars {
  margin-top: 6rem;
}

.button-section {
  text-align: center;
}
.button-section .button {
  width: 250px;
}
.button-section [class*="col-"] .button:first-child {
  margin-right: 4rem;
}

/**
 * responsive:
 * about
 * ------------------------------------------------------------------- 
 */
@media only screen and (max-width: 1024px) {
  .intro-info {
    margin-right: 0;
    margin-left: 0;
  }
}
@media only screen and (max-width: 768px) {
  .intro-info img {
    height: 7.8rem;
    width: 7.8rem;
  }
  .intro-info .lead {
    padding-left: 11rem;
  }
  .about-content h3 {
    text-align: center;
  }
  .about-content .info-list,
  .about-content .skill-bars {
    margin-bottom: 4.2rem;
  }
  .button-section .button {
    width: 100%;
    margin-bottom: 3rem;
  }
  .button-section [class*="col-"] .button:first-child {
    margin-right: 0;
  }
}
@media only screen and (max-width: 600px) {
  .intro-info {
    text-align: center;
    margin-top: 3rem;
  }
  .intro-info img {
    height: 9.6rem;
    width: 9.6rem;
    float: none;
    display: inline-block;
  }
  .intro-info .lead {
    padding-left: 0;
    text-align: center;
  }
}

/** 
 * ===================================================================
 * 13. portfolio - (_layout.scss)
 *
 * ------------------------------------------------------------------- 
 */
#portfolio {
  background: #ffffff;
  padding-top: 12rem;
  padding-bottom: 12rem;
}
#portfolio .folio-item {
  padding: 0;
  position: relative;
  overflow: hidden;
}
#portfolio .folio-item img {
  vertical-align: middle;
  transition: all 0.5s ease-in-out;
}

#portfolio .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  transition: all 0.5s ease-in-out;
}

#portfolio .folio-item-table {
  display: table;
  width: 100%;
  height: 100%;
}
#portfolio .folio-item-cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  transition: all 0.5s ease-in-out;
  position: relative;
  left: -100%;
}

#portfolio .folio-title {
  color: #ffffff;
  font-size: 3.3rem;
  padding: 0 3rem;
  margin-bottom: 0;
}
#portfolio .folio-types {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-family: "poppins-regular", sans-serif;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  color: rgba(255, 255, 255, 0.6);
}
#portfolio .folio-item:hover .overlay {
  background: rgba(0, 0, 0, 0.8);
}
#portfolio .folio-item:hover .folio-item-cell {
  left: 0;
}
#portfolio .folio-item:hover img {
  transform: scale(1.05);
}

/**
 * responsive:
 * portfolio
 * ------------------------------------------------------------------- 
 */
@media only screen and (max-width: 1024px) {
  #portfolio .folio-title {
    font-size: 3.1rem;
  }
}
@media only screen and (max-width: 768px) {
  #portfolio .folio-title {
    font-size: 2.5rem;
  }
}
@media only screen and (max-width: 400px) {
  #portfolio .folio-title {
    font-size: 2.2rem;
  }
}

/** 
 * ===================================================================
 * 17. contact - (_layout.scss)
 *
 * ------------------------------------------------------------------- 
 */
#contact {
  background: #151515;
  padding-top: 12rem;
  padding-bottom: 7.2rem;
}
#contact .section-intro h1 {
  color: white;
}
#contact .section-intro h5 {
  color: #ff0077;
}
#contact .section-intro p {
  color: rgba(255, 255, 255, 0.7);
}

/* contact form */
.contact-form {
  max-width: 740px;
}

.contact-form ::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.contact-form :-moz-placeholder {
  color: rgba(255, 255, 255, 0.3); /* Firefox 18- */
}
.contact-form ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.3); /* Firefox 19+ */
}
.contact-form :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.contact-form .placeholder {
  color: rgba(255, 255, 255, 0.3) !important;
}

#contact form {
  margin-top: 0;
  margin-bottom: 3rem;
}
#contact form .form-field {
  position: relative;
}
#contact form .form-field:before,
#contact form .form-field:after {
  content: "";
  display: table;
}
#contact form .form-field:after {
  clear: both;
}
#contact form .form-field label {
  font-family: "poppins-bold", sans-serif;
  font-size: 1.1rem;
  line-height: 2.4rem;
  position: absolute;
  bottom: -1.2rem;
  right: 0.6rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  padding: 0 2rem;
  margin: 0;
  color: #ffffff;
  background: #ff0077;
}
#contact form .form-field label::after {
  position: absolute;
  left: -5px;
  top: 50%;
  margin-top: -6px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #ff0077;
  content: "";
}
#contact input[type="text"],
#contact input[type="password"],
#contact input[type="email"],
#contact textarea {
  width: 100%;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
#contact input[type="text"],
#contact input[type="password"],
#contact input[type="email"] {
  height: 6.6rem;
  padding: 1.8rem 2rem;
}
#contact input[type="text"]:focus,
#contact input[type="password"]:focus,
#contact input[type="email"]:focus {
  border-color: #ff0077;
  color: #ffffff;
}
#contact textarea {
  min-height: 20rem;
  padding: 1.8rem 2rem;
}
#contact textarea:focus {
  border-color: #ff0077;
  color: #ffffff;
}
#contact button.submitform {
  font-size: 1.5rem;
  display: block;
  letter-spacing: 0.2rem;
  height: 6.6rem;
  line-height: 6.6rem;
  padding: 0 3rem;
  margin-top: 4.8rem;
  width: 100%;
  background: #ff0077;
  color: #ffffff;
}
#contact button.submitform:hover,
#contact button.submitform:focus {
  background: #cc005f;
}
#message-warning,
#message-success {
  display: none;
  background: #0d0d0d;
  border-radius: 3px;
  padding: 3rem;
  margin-bottom: 3.6rem;
  width: 100%;
}
#message-warning {
  color: #fa0003;
}
#message-success {
  color: #ff0077;
}
#message-warning i,
#message-success i {
  margin-right: 10px;
}

/* form loader */
#submit-loader {
  display: none;
  position: relative;
  left: 0;
  top: 1.8rem;
  width: 100%;
  text-align: center;
}
#submit-loader .text-loader {
  display: none;
  font-family: "poppins-bold", sans-serif;
  color: #ffffff;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
}
.oldie #submit-loader .s-loader {
  display: none;
}
.oldie #submit-loader .text-loader {
  display: block;
}
.contact-info {
  margin: 4.8rem auto 0;
  font-family: "poppins-regular", sans-serif;
  font-size: 1.5rem;
  text-align: center;
}
.contact-info .collapse {
  padding: 0;
}
.contact-info .icon {
  margin-bottom: 2.1rem;
}
.contact-info .icon i {
  font-size: 4.2rem;
  color: #ffffff;
}
.contact-info h5 {
  color: #ff0077;
}

/**
 * loader animation - (_layout.scss)
 * --------------------------------------------------------------- 
 */
.s-loader {
  margin: 1.2rem auto 3rem;
  width: 70px;
  text-align: center;
  transform: translateX(0.45rem);
}
.s-loader > div {
  width: 1rem;
  height: 1rem;
  background-color: #ffffff;
  border-radius: 100%;
  display: inline-block;
  margin-right: 0.9rem;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.s-loader .bounce1 {
  animation-delay: -0.32s;
}
.s-loader .bounce2 {
  animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

/** 
 * ===================================================================
 * 18. footer - (_layout.scss)
 *
 * ------------------------------------------------------------------- 
 */
footer {
  padding-top: 7rem;
  padding-bottom: 0.1rem;
  font-size: 1.4rem;
  font-family: "poppins-regular", sans-serif;
}
footer a,
footer a:visited {
  color: #ffffff;
}
footer a:hover,
footer a:focus {
  color: #ff0077;
}
footer .row {
  max-width: 900px;
}
footer .social {
  text-align: right;
}
footer .footer-social {
  display: inline-block;
  font-size: 2.1rem;
  margin: 0;
  padding: 0;
  position: relative;
  top: -0.3rem;
}
footer .footer-social li {
  display: inline-block;
  margin: 0 12px;
  padding: 0;
}
footer .footer-social li a {
  color: #ffffff;
}
footer .copyright span {
  display: inline-block;
}
footer .copyright span::after {
  content: "|";
  display: inline-block;
  padding: 0 1rem 0 1.2rem;
  color: rgba(255, 255, 255, 0.1);
}
footer .copyright span:last-child::after {
  display: none;
}

/**
 * responsive:
 * footer
 * -------------------------------------------------------------------
 */
@media only screen and (max-width: 768px) {
  footer {
    text-align: center;
  }
  footer .social {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  footer .copyright span {
    display: block;
  }
  footer .copyright span::after {
    display: none;
  }
}

/* CSS Typing Effect */

.container {
  display: inline-block;
}
.typed-out {
  overflow: hidden;
  border-right: 0.15em solid #cc005f;
  white-space: nowrap;
  animation: typing 3s steps(20, end) forwards, blinking 1s infinite;
  font-size: 1.6rem;
  width: 0;
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes blinking {
  from {
    border-color: transparent;
  }
  to {
    border-color: #cc005f;
  }
}
/*//////////////////////////*/
.container2 {
  display: inline-block;
}
.typed-out2 {
  overflow: hidden;
  border-right: 0.15em;
  white-space: nowrap;
  animation: typing2 2.8s steps(20, end) forwards;
  font-size: 1.6rem;
  width: 0;
}
@keyframes typing2 {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* background picture fitting */

#bg {
  position: fixed;
  top: 0;
  left: 0;
}
.bgwidth {
  width: 100%;
}
.bgheight {
  height: 100%;
}
