html {
  font-family: sans-serif;
}

body {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background: transparent;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="search"] {
  box-sizing: content-box;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

/** 
 * ===================================================================
 * 02. basic/base setup styles - (_basic.scss)
 *
 * ------------------------------------------------------------------- 
 */
html {
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-weight: normal;
  line-height: 1;
  text-rendering: optimizeLegibility;
  word-wrap: break-word;
}

/**
 * Media - (_basic.scss)
 * ------------------------------------------------------------------- 
 */
img,
video {
  max-width: 100%;
  height: auto;
}

/**
 * Typography resets - (_basic.scss)
 * ------------------------------------------------------------------- 
 */
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-variant-ligatures: common-ligatures;
  text-rendering: optimizeLegibility;
}

em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: bold;
  line-height: inherit;
}

small {
  font-size: 60%;
  line-height: inherit;
}

ol,
ul {
  list-style: none;
}

li {
  display: block;
}

/**
 * links - (_basic.scss)
 * ------------------------------------------------------------------- 
 */
a {
  text-decoration: none;
  line-height: inherit;
}

a img {
  border: none;
}

/**
 * inputs - (_basic.scss)
 * ------------------------------------------------------------------- 
 */
fieldset {
  margin: 0;
  padding: 0;
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
  appearance: none;
}

/** 
 * ===================================================================
 * 03. grid - (_grid.scss)
 *
 * ------------------------------------------------------------------- 
 */
.row {
  width: 94%;
  max-width: 1140px;
  margin: 0 auto;
}

.row:before,
.row:after {
  content: "";
  display: table;
}

.row:after {
  clear: both;
}

.row .row {
  width: auto;
  max-width: none;
  margin-left: -20px;
  margin-right: -20px;
}

[class*="col-"],
.bgrid {
  float: left;
}

[class*="col-"] + [class*="col-"].end {
  float: right;
}

[class*="col-"] {
  padding: 0 20px;
}

.col-one {
  width: 8.33333%;
}

.col-two,
.col-1-6 {
  width: 16.66667%;
}

.col-three,
.col-1-4 {
  width: 25%;
}

.col-four,
.col-1-3 {
  width: 33.33333%;
}

.col-five {
  width: 41.66667%;
}

.col-six,
.col-1-2 {
  width: 50%;
}

.col-seven {
  width: 58.33333%;
}

.col-eight,
.col-2-3 {
  width: 66.66667%;
}

.col-nine,
.col-3-4 {
  width: 75%;
}

.col-ten,
.col-5-6 {
  width: 83.33333%;
}

.col-eleven {
  width: 91.66667%;
}

.col-twelve,
.col-full {
  width: 100%;
}

/**
 * small screens - (_grid.scss)
 * --------------------------------------------------------------- 
 */
@media screen and (max-width: 1024px) {
  .row .row {
    margin-left: -18px;
    margin-right: -18px;
  }

  [class*="col-"] {
    padding: 0 18px;
  }
}

/**
 * tablets - (_grid.scss)
 * --------------------------------------------------------------- 
 */
@media screen and (max-width: 768px) {
  .row {
    width: 100%;

    padding-left: 30px;
    padding-right: 30px;
  }

  .row .row {
    padding-left: 0;
    padding-right: 0;
    margin-left: -15px;
    margin-right: -15px;
  }

  [class*="col-"] {
    padding: 0 15px;
  }

  .tab-1-4 {
    width: 25%;
  }

  .tab-1-3 {
    width: 33.33333%;
  }

  .tab-1-2 {
    width: 50%;
  }

  .tab-2-3 {
    width: 66.66667%;
  }

  .tab-3-4 {
    width: 75%;
  }

  .tab-full {
    width: 100%;
  }
}

/**
 * large mobile devices - (_grid.scss)
 * --------------------------------------------------------------- 
 */
@media screen and (max-width: 600px) {
  .row {
    padding-left: 25px;
    padding-right: 25px;
  }

  .row .row {
    margin-left: -10px;
    margin-right: -10px;
  }

  [class*="col-"] {
    padding: 0 10px;
  }

  .mob-1-4 {
    width: 25%;
  }

  .mob-1-2 {
    width: 50%;
  }

  .mob-3-4 {
    width: 75%;
  }

  .mob-full {
    width: 100%;
  }
}

/**
 * small mobile devices - (_grid.scss)
 * --------------------------------------------------------------- 
 */
@media screen and (max-width: 400px) {
  .row .row {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }

  [class*="col-"] {
    width: 100% !important;
    float: none !important;
    clear: both !important;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }

  [class*="col-"] + [class*="col-"].end {
    float: none;
  }
}

/** 
 * ===================================================================
 * block grids - (_grid.scss)
 *
 * ------------------------------------------------------------------- 
 */
[class*="block-"]:before,
[class*="block-"]:after {
  content: "";
  display: table;
}

[class*="block-"]:after {
  clear: both;
}

.block-1-6 .bgrid {
  width: 16.66667%;
}

.block-1-4 .bgrid {
  width: 25%;
}

.block-1-3 .bgrid {
  width: 33.33333%;
}

.block-1-2 .bgrid {
  width: 50%;
}

/**
 * Clearing for block grid columns. Allow columns with 
 * different heights to align properly.
 */
.block-1-6 .bgrid:nth-child(6n + 1),
.block-1-4 .bgrid:nth-child(4n + 1),
.block-1-3 .bgrid:nth-child(3n + 1),
.block-1-2 .bgrid:nth-child(2n + 1) {
  clear: both;
}

/**
 * small screens - (_grid.scss)
 * --------------------------------------------------------------- 
 */
@media screen and (max-width: 1024px) {
  .block-s-1-6 .bgrid {
    width: 16.66667%;
  }

  .block-s-1-4 .bgrid {
    width: 25%;
  }

  .block-s-1-3 .bgrid {
    width: 33.33333%;
  }

  .block-s-1-2 .bgrid {
    width: 50%;
  }

  .block-s-full .bgrid {
    width: 100%;
    clear: both;
  }

  [class*="block-s-"] .bgrid:nth-child(n) {
    clear: none;
  }

  .block-s-1-6 .bgrid:nth-child(6n + 1),
  .block-s-1-4 .bgrid:nth-child(4n + 1),
  .block-s-1-3 .bgrid:nth-child(3n + 1),
  .block-s-1-2 .bgrid:nth-child(2n + 1) {
    clear: both;
  }
}

/**
 * tablets - (_grid.scss)
 * --------------------------------------------------------------- 
 */
@media screen and (max-width: 768px) {
  .block-tab-1-6 .bgrid {
    width: 16.66667%;
  }

  .block-tab-1-4 .bgrid {
    width: 25%;
  }

  .block-tab-1-3 .bgrid {
    width: 33.33333%;
  }

  .block-tab-1-2 .bgrid {
    width: 50%;
  }

  .block-tab-full .bgrid {
    width: 100%;
    clear: both;
  }

  [class*="block-tab-"] .bgrid:nth-child(n) {
    clear: none;
  }

  .block-tab-1-6 .bgrid:nth-child(6n + 1),
  .block-tab-1-4 .bgrid:nth-child(4n + 1),
  .block-tab-1-3 .bgrid:nth-child(3n + 1),
  .block-tab-1-2 .bgrid:nth-child(2n + 1) {
    clear: both;
  }
}

/**
 * large mobile devices - (_grid.scss)
 * --------------------------------------------------------------- 
 */
@media screen and (max-width: 600px) {
  .block-mob-1-6 .bgrid {
    width: 16.66667%;
  }

  .block-mob-1-4 .bgrid {
    width: 25%;
  }

  .block-mob-1-3 .bgrid {
    width: 33.33333%;
  }

  .block-mob-1-2 .bgrid {
    width: 50%;
  }

  .block-mob-full .bgrid {
    width: 100%;
    clear: both;
  }

  [class*="block-mob-"] .bgrid:nth-child(n) {
    clear: none;
  }

  .block-mob-1-6 .bgrid:nth-child(6n + 1),
  .block-mob-1-4 .bgrid:nth-child(4n + 1),
  .block-mob-1-3 .bgrid:nth-child(3n + 1),
  .block-mob-1-2 .bgrid:nth-child(2n + 1) {
    clear: both;
  }
}

/**
 * stack on small mobile devices - (_grid.scss)
 * --------------------------------------------------------------- 
 */
@media screen and (max-width: 400px) {
  .stack .bgrid {
    width: 100% !important;
    float: none !important;
    clear: both !important;
    margin-left: 0;
    margin-right: 0;
  }
}

/* Social buttons */
.rounded-social-buttons {
  text-align: center;
}

.rounded-social-buttons .social-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 3.125rem;
  height: 3.125rem;
  border: 0.125rem solid transparent;
  padding: 0;
  text-decoration: none;
  text-align: center;
  color: #fefefe;
  font-size: 1.5625rem;
  font-weight: normal;
  line-height: 2em;
  border-radius: 1.6875rem;
  transition: all 0.5s ease;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
}

.rounded-social-buttons .social-button:hover,
.rounded-social-buttons .social-button:focus {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.rounded-social-buttons .social-button.linkedin {
  background: #007bb5;
}

.rounded-social-buttons .social-button.linkedin:hover,
.rounded-social-buttons .social-button.linkedin:focus {
  color: #007bb5;
  background: #fefefe;
  border-color: #007bb5;
}

.rounded-social-buttons .social-button.github {
  background: #000000;
}

.rounded-social-buttons .social-button.github:hover,
.rounded-social-buttons .social-button.github:focus {
  color: #000000;
  background: #fefefe;
  border-color: #000000;
}
